const amidata  = {
    "name": "Asmita Limaye",
    "homepage": "https://linkedin.com/in/asmita-limaye",
    "githubURL": "https://github.com/asmital",
    "interestingFact": "I like eating ice",
    "skills": [
        "linux",
        "c",
        "c++",
        "java",
        "python"
    ]
};

export default amidata ; 
